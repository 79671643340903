$WRAPPER_PADDING: 7vw;
%wrapper {
    padding: 0 $WRAPPER_PADDING;
    @include clearfix();
    position: relative;
}

%sidebar {
    position: relative;
	float: left;
	width: 30%;
	margin-right: 5%;
    min-height: 1px; // prevent horizontal collapse

	@include respond-to("max-width:" + $SMALL_SCREEN_WIDTH) {
		width: auto;
		float: none;
		margin-right: 0;
		margin-top: 30px;
		padding-top: 30px;
		border-top: 1px solid $LIGHT_GREY;
	}

}
%main-column {
	float: right;
	width: 65%;
    min-height: 1px; // prevent horizontal collapse
	@include respond-to("max-width:" + $SMALL_SCREEN_WIDTH) {
		width: auto;
		float: none;
	}
}

// 2 column layout class
.two-column-layout {
    @include clearfix();
	.sidebar {
		@extend %sidebar;
	}
	.main-column {
		@extend %main-column;
	}
}

.one-column-layout {
    .main-column {
        @include block-center();
        max-width: 1500px;
    }
    .sidebar {
        display: none;
    }
}
.one-column-narrow-layout .main-column {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
