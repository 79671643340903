.home {

}


.home .section-header {
    background: $LIGHT_GREY url(../images/backgrounds/join-us-bg.png) no-repeat 50% 50%;
    background-size: cover;
    border-bottom: 2px solid $ORANGE;
    @include respond-to("min-width: " + $MEDIUM_SCREEN_WIDTH) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .hp-slider-container {
            width: 55vw;
        }
        .join-us-callout {
            width: 45vw;
        }
    }
    @include respond-to("min-width: " + $LARGE_SCREEN_WIDTH) {
        .hp-slider-container {
            width: 65vw;
        }
        .join-us-callout {
            width: 35vw;
        }
    }

}

.hp-slider-container {

}

.hp-slider-container .slide {
    color: white;
    position: relative;
    .title {
        margin-bottom: 1rem;
        font-size: calc(2rem + 0.5vw);
        color: rgba(white, 0.9);
        text-transform: uppercase;
        font-weight: bold;
    }
    .slide-content {
        background-color: $DARK_BLUE;
        padding: 10px 15px;
        p {
            font-size: 1.2rem;
        }
        p:last-of-type {
            margin-bottom: 0;
        }
    }
    @include respond-to("min-width: " + $SMALL_SCREEN_WIDTH) {
        .slide-content {
            background-color: transparent;
            position: absolute;
            left: 0;
            right: 0;
            padding-left: 20px;
            padding-right: 20px;
            width: 100%;
            bottom: 15px;
            .title {
                font-size: calc(3rem + 0.5vw);
            }
            p {
                font-size: 1.4rem;
            }
        }
    }
}
.home .join-us-callout {
    padding: 10px 4vw;
    font-size: 1.5rem;
    .title {
        color: $DARK_BLUE;
        font-size: $page_header_font_size;
        line-height: 1.25;
        margin-bottom: 2rem;
    }
    @include respond-to("min-width: " + $LARGE_SCREEN_WIDTH) {
        .title {
            font-size: $page_header_font_size;
        }
    }
}

.home .panel.who-we-are {
}
.home .panel.who-we-serve {
    background-color: $DARK_BLUE;
    color: white;
    .title, a {
        color: white;
    }
    a {
        text-decoration: none;
    }
}
.home .panel.who-we-serve .banner-list {
    .banner {
        padding: 20px 10px;
        .title {
            text-transform: uppercase;
            font-size: 2.4rem;
            margin-bottom: 1rem;
        }
        p {
            font-size: 1.4rem;
        }
        .button {
            font-size: 1.4rem;
        }
    }
    @include respond-to("min-width: " + $SMALL_SCREEN_WIDTH) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: calc(-1 * (#{$WRAPPER_PADDING} / 2.5));
        margin-right: calc(-1 * (#{$WRAPPER_PADDING} / 2.5));
        .banner {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
    @include respond-to("min-width: " + $LARGE_SCREEN_WIDTH) {
        margin-left: calc(-1 * (#{$WRAPPER_PADDING} / 1.5));
        margin-right: calc(-1 * (#{$WRAPPER_PADDING} / 1.5));
        .banner {
            width: 20%;
        }
    }
}
.home .main-content-wrapper {
    padding: 0;
}

.home .panel.what-we-do {
    
    .banner-list {

    }
    .banner {
        -webkit-font-smoothing: antialiased;
        background-color: $DARK_GREY;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: cover;
        text-align: center;
        overflow: hidden;
        a {
            display: block;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            text-decoration: none;

            padding-top: 25vw;
            padding-bottom: 25vw;
            &:hover .title {
                transform: scale(1.15);
                color: rgba(white, 0.8);
            }

        }
        .title {
            text-transform: uppercase;
            color: $WHITE;
            font-weight: bold;
            font-size: 2.4rem;
            margin-bottom: 0;
            transition: transform ease 0.2s,
                        color ease-in 0.3s;
        }
        &.preserve {
            background-image: url(../images/what-we-do/preserve.png);
        }
        &.advocate {
            background-image: url(../images/what-we-do/advocate.png);
        }
        &.educate {
            background-image: url(../images/what-we-do/educate.png);
        }
        &.events {
            background-image: url(../images/what-we-do/events.png);
        }
    }
    @include respond-to("min-width: " + $VERY_SMALL_SCREEN_WIDTH) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

        .banner-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: stretch;
        }
        .banner {
            width: 50%;
            a {
                padding-top: 20vw;
                padding-bottom: 20vw;
            }
        }
    }
    @include respond-to("min-width: " + $MEDIUM_SCREEN_WIDTH) {
        .banner {
            width: 25%;
            a {
                padding-top: 10vw;
                padding-bottom: 10vw;
            }
        }
    }
}

.info-panel {
    background-color: lighten($LIGHT_GREY, 5%);
    .col-container > div {
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid $LIGHT_GREY;
    }
    .panel-col-title {
        color: $ORANGE;
        font-size: 3.6rem;
        margin-bottom: 4rem;
    }
    .curran-hall-callout {
        img {
            box-shadow: $BOX_SHADOW;
            margin-bottom: 20px;
        }
    }
    .blog-feed {
        > footer {
            padding-top: 30px;
            display: flex;
            justify-content: flex-end;
        }
    }
    @include respond-to("min-width: " + $SMALL_SCREEN_WIDTH) {
        .col-container {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
        }
        .col-container > div {
            width: 48%;
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
        }
    }
}

.home .donors {
    .panel-footer {
        text-align: center;
    }
}
