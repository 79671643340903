$SANS_SERIF_FONT_STACK: "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif;
$SERIF_FONT_STACK: 'Georgia', 'Times New Roman', serif;

$BODY_FONT_STACK: 'Open Sans', $SANS_SERIF_FONT_STACK;
$HEADING_FONT_STACK: 'Open Sans', $SANS_SERIF_FONT_STACK;

@mixin heading-font {
    font-family: $HEADING_FONT_STACK;
    -webkit-font-smoothing: antialiased;
}

// A simple rem font size mixin assume 62.5% for html element
@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}
