@import "modules/my_compass";
@mixin scalable-font-size($min_size: 2em, $scale_factor: 1vw) {
    font-size: $min_size;
    font-size: calc(#{$min_size} + #{$scale_factor});
}
@mixin block-center {
    margin: {
        left: auto;
        right: auto;
    }
}
@mixin button($background-color: $PRIMARY_BUTTON_BACKGROUND_COLOR, $color: $PRIMARY_BUTTON_TXT_COLOR) {
    background-color: $background-color;
    display: inline-block;
    border: 0;
    font-size: 1.4rem;
    line-height: 1;
    font-weight: bold;
    padding: 10px 24px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    color: $color;
    @include heading-font();
    appearance: none;
     -moz-appearance: none;
     -webkit-appearance: none;

    i {
        margin-left: 10px;
    }
    transition: background-color ease-in 0.2s;

    &:hover {
        background-color: darken($background-color, 10%);
        color: $color;
    }
}

// Horizontal Nav
%horizontal-nav {
    @include inline-block-list();

    li:last-child {
        margin-right: 0;
    }

    a {
        display: block;
    }
}

// Flex Horizontal Nav
%flex-horizontal-nav {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {

    }
    a {
        display: block;
        padding: 4px;
    }
}

// Media object from OOCSS
%media {
    @include clearfix;

    .media-img {
        display: block;
        float: left;
        margin-right: 4%;
        width: 30%;

        > img {
            display: block;
        }
    }

    .media-body {
        @include clearfix;
        float: right;
        width: 66%;
    }
    @include respond-to("max-width: " + $VERY_SMALL_SCREEN_WIDTH) {
        .media-body,
        .media-img {
            float: none;
            width: auto;
            margin: 0;
        }

        .media-img {
            margin-bottom: 20px;
        }
    }
}
// For Article Types (blog posts, events )
%article-list-item {
    padding-bottom: 40px;
    margin-bottom: 40px;

    header {
        margin-bottom: 1.25rem;

        .title {
            @include heading-font();
            font-size: 2.5rem;
            margin-bottom: 1rem;

            a {}
        }

        time {
            display: block;
            font-size: 1.3rem;
            margin-bottom: 0;
            text-transform: uppercase;
            color: $MEDIUM_GREY;
        }
    }

    .media-img {
        margin-bottom: 25px;

        img {
            display: block;
            border: 1px solid $LIGHT_GREY;
            width: 100%;
        }
    }

    .excerpt {
        margin-bottom: 2.2rem;
        color: $MEDIUM_GREY;
        line-height: 1.5;
        font-size: 1.6rem;
    }

    .more,
    .read-more {
        @include button();
    }

    &:last-child {
        margin-bottom: 0;
    }
    @include respond-to("max-width:" + $VERY_SMALL_SCREEN_WIDTH) {
        header {
            .title {
                font-size: 2rem;
            }
        }

        .excerpt {
            font-size: 1.5rem;
        }
    }
}

%article {
    > header {
        > .title {}

        > time {
            display: block;
            text-transform: uppercase;
            margin-bottom: 1rem;
            color: $MEDIUM_GREY;
        }
    }
}
// Video Thumbnail

%video-thumbnail {
    display: block;
    position: relative;

    img {
        display: block;
        width: 100%;
    }

    &:after {
        content: '';
        background: transparent url("../images/play-icon.png") no-repeat 0 0;
        // Adjust based on size of background image icon
        width: 80px;
        height: 80px;
        margin-top: -40px;
        margin-left: -40px;
        // ***************
        top: 50%;
        left: 50%;
        position: absolute;
        z-index: 100;
        transition: transform ease-in 0.3s, opacity ease-in 0.5s;
    }

    &:hover {
        &:after {
            opacity: 0.8;
            transform: scale(0.8);
        }
    }
}
@mixin article-flex-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > * {
        width: 100%;
    }
    @include respond-to("min-width:" + $SMALL_SCREEN_WIDTH) {
        > * {
            width: 46%;
        }
    }
    @include respond-to("min-width:" + $LARGE_SCREEN_WIDTH) {
        justify-content: flex-start;

        > * {
            width: 33%;
            padding: 0 20px;
        }
    }
}

.button-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .button {
        min-width: 200px;
        margin: 5px 10px;
    }
}
