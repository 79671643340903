.post {
	@extend %article;
    header {
        time {
            span {
                display: none;
            }
        }
    }
}

.post-list .post {
	@extend %media;
	@extend %article-list-item;
}
