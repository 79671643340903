.slider {
    position: relative;
}
// Arrows
.slick-prev,
.slick-next {
    -webkit-font-smoothing: antialiased;
    position: absolute;
    display: block;
    height: 40px;
    width: 40px;
    padding: 0;
    line-height: 0px;
    font-size: 40px;
    font-weight: normal;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    color: rgba($WHITE, 0.3);
    top: 40%;
    z-index: 1000;
    transform: translate(0, -50%);
    border: none;
    outline: none;
    i, svg {
        margin: 0;
    }
    &:hover {
    }
    &:hover, &:focus {
        outline: none;
        color: rgba(white, 0.9);
        background-color: transparent;
    }
}

.slick-prev {
    left: 0;
}

.slick-next {
    right: 0;
}
@include respond-to("min-width: " + $SMALL_SCREEN_WIDTH) {
    .slick-prev,
    .slick-next {
        top: 50%;
    }
    .slick-prev {
        left: 5px;
    }
    .slick-next{
        right: 5px;
    }
}

.slick-slide img {
}

// Loading State
.slick-loading .slick-list {
}
