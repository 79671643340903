@charset "UTF-8";
/* RESET rules from Eric Meyer http://meyerweb.com/eric/tools/css/reset/  */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*** BASIC TYPOGRAPHY RULES BORROWED FROM BLUEPRINT */
/* Default font settings.
The font-size percentage is of 16px. (0.75 * 16px = 12px) */
html {
  font-size: 100.01%; }

body {
  font-size: 100%;
  /* 16px */
  color: #222;
  background: #fff;
  font-family: "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif; }

/* Headings
-------------------------------------------------------------- */
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  color: #111; }

h1 {
  font-size: 2.5em;
  line-height: 1;
  margin-bottom: 0.5em; }

h2 {
  font-size: 1.9em;
  margin-bottom: 0.75em; }

h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em; }

h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em; }

h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em; }

h6 {
  font-size: 1em;
  font-weight: bold; }

h1 img, h2 img, h3 img,
h4 img, h5 img, h6 img {
  margin: 0; }

/* Text elements
-------------------------------------------------------------- */
p {
  margin: 0 0 1.5em;
  line-height: 1.5; }

a:focus,
a:hover {
  color: #09f; }

a {
  color: #06c;
  text-decoration: underline; }

blockquote {
  margin: 1.5em;
  color: #666;
  font-style: italic; }

strong, dfn {
  font-weight: bold; }

em, dfn {
  font-style: italic; }

sup, sub {
  line-height: 0; }

abbr,
acronym {
  border-bottom: 1px dotted #666; }

address {
  margin: 0 0 1.5em;
  font-style: italic; }

del {
  color: #666; }

pre {
  margin: 1.5em 0;
  white-space: pre; }

pre, code, tt {
  font: 1em 'andale mono', 'lucida console', monospace;
  line-height: 1.5; }

/* Lists
-------------------------------------------------------------- */
li ul,
li ol {
  margin: 0; }

ul, ol {
  margin: 0 1.5em 1.5em 0;
  padding-left: 1.5em; }

ul {
  list-style-type: disc; }

ol {
  list-style-type: decimal; }

dl {
  margin: 0 0 1.5em 0; }

dl dt {
  font-weight: bold; }

dd {
  margin-left: 1.5em; }

/* Tables
-------------------------------------------------------------- */
/*
Because of the need for padding on TH and TD, the vertical rhythm
on table cells has to be 27px, instead of the standard 18px or 36px
of other elements.
*/
table {
  margin-bottom: 1.4em;
  width: 100%;
  border: 1px solid #C9C68F; }

th {
  font-weight: bold; }

thead th {
  background: #E9E6AF;
  border-bottom: 1px solid #C9C68F;
  font-family: "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif !important; }

th, td, caption {
  padding: 10px 15px 10px 10px; }

/*
You can zebra-stripe your tables in outdated browsers by adding
the class "even" to every other table row.
*/
tbody tr:nth-child(even) td,
tbody tr.even td {
  background: #E9E6AF; }

tfoot {
  font-style: italic; }

caption {
  background: #758053;
  font-size: 1.2em;
  font-weight: bold;
  color: white;
  -webkit-text-shadow: rgba(0, 0, 0, 0.5) -1px 0, rgba(0, 0, 0, 0.3) 0 -1px, rgba(255, 255, 255, 0.5) 0 1px, rgba(0, 0, 0, 0.3) -1px -2px;
  -moz-text-shadow: rgba(0, 0, 0, 0.5) -1px 0, rgba(0, 0, 0, 0.3) 0 -1px, rgba(255, 255, 255, 0.5) 0 1px, rgba(0, 0, 0, 0.3) -1px -2px;
  text-shadow: rgba(0, 0, 0, 0.5) -1px 0, rgba(0, 0, 0, 0.3) 0 -1px, rgba(255, 255, 255, 0.5) 0 1px, rgba(0, 0, 0, 0.3) -1px -2px; }

/* Misc classes
-------------------------------------------------------------- */
.small {
  font-size: .8em;
  margin-bottom: 1.875em;
  line-height: 1.875em; }

.large {
  font-size: 1.2em;
  line-height: 2.5em;
  margin-bottom: 1.25em; }

.hide {
  display: none; }

.quiet {
  color: #666; }

.loud {
  color: #000; }

.highlight {
  background: #ff0; }

.added {
  background: #060;
  color: #fff; }

.removed {
  background: #900;
  color: #fff; }

.first {
  margin-left: 0;
  padding-left: 0; }

.last {
  margin-right: 0;
  padding-right: 0; }

.top {
  margin-top: 0;
  padding-top: 0; }

.bottom {
  margin-bottom: 0;
  padding-bottom: 0; }

/* MISC STYLES, clears, etc. */
/* Clearing floats without extra markup
Based on How To Clear Floats Without Structural Markup by PiE
[http://www.positioniseverything.net/easyclearing.html] */
.clearfix:after, .container:after {
  content: "\0020";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  overflow: hidden; }

.clearfix, .container {
  display: block; }

/* Regular clearing
apply to column that should drop below previous ones. */
.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0; }

.post-list .post:after {
  content: "";
  display: table;
  clear: both; }

.post-list .post .media-img {
  display: block;
  float: left;
  margin-right: 4%;
  width: 30%; }
  .post-list .post .media-img > img {
    display: block; }

.post-list .post .media-body {
  float: right;
  width: 66%; }
  .post-list .post .media-body:after {
    content: "";
    display: table;
    clear: both; }

@media screen and (max-width: 450px) {
  .post-list .post .media-body, .post-list .post .media-img {
    float: none;
    width: auto;
    margin: 0; }
  .post-list .post .media-img {
    margin-bottom: 20px; } }

.post-list .post {
  padding-bottom: 40px;
  margin-bottom: 40px; }
  .post-list .post header {
    margin-bottom: 1.25rem; }
    .post-list .post header .title {
      font-family: "Open Sans", "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif;
      -webkit-font-smoothing: antialiased;
      font-size: 2.5rem;
      margin-bottom: 1rem; }
    .post-list .post header time {
      display: block;
      font-size: 1.3rem;
      margin-bottom: 0;
      text-transform: uppercase;
      color: #777; }
  .post-list .post .media-img {
    margin-bottom: 25px; }
    .post-list .post .media-img img {
      display: block;
      border: 1px solid #dfdfdf;
      width: 100%; }
  .post-list .post .excerpt {
    margin-bottom: 2.2rem;
    color: #777;
    line-height: 1.5;
    font-size: 1.6rem; }
  .post-list .post .more,
  .post-list .post .read-more {
    background-color: #5E98B0;
    display: inline-block;
    border: 0;
    font-size: 1.4rem;
    line-height: 1;
    font-weight: bold;
    padding: 10px 24px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    color: #F6F5F6;
    font-family: "Open Sans", "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    transition: background-color ease-in 0.2s; }
    .post-list .post .more i,
    .post-list .post .read-more i {
      margin-left: 10px; }
    .post-list .post .more:hover,
    .post-list .post .read-more:hover {
      background-color: #487d93;
      color: #F6F5F6; }
  .post-list .post:last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 450px) {
    .post-list .post header .title {
      font-size: 2rem; }
    .post-list .post .excerpt {
      font-size: 1.5rem; } }

.post > header > time {
  display: block;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: #777; }

.button-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }
  .button-bar .button {
    min-width: 200px;
    margin: 5px 10px; }

.main-header, .main-content-wrapper, .main-footer, .panel {
  padding: 0 7vw;
  position: relative; }
  .main-header:after, .main-content-wrapper:after, .main-footer:after, .panel:after {
    content: "";
    display: table;
    clear: both; }

.two-column-layout .sidebar {
  position: relative;
  float: left;
  width: 30%;
  margin-right: 5%;
  min-height: 1px; }
  @media screen and (max-width: 770px) {
    .two-column-layout .sidebar {
      width: auto;
      float: none;
      margin-right: 0;
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid #dfdfdf; } }

.two-column-layout .main-column {
  float: right;
  width: 65%;
  min-height: 1px; }
  @media screen and (max-width: 770px) {
    .two-column-layout .main-column {
      width: auto;
      float: none; } }

.two-column-layout:after {
  content: "";
  display: table;
  clear: both; }

.one-column-layout .main-column {
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px; }

.one-column-layout .sidebar {
  display: none; }

.one-column-narrow-layout .main-column {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto; }

*, *:before, *:after {
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  /* 10/16 make - this make em/rem math a power of 10 */ }

body {
  font-size: 16px;
  font-size: 1.6rem;
  min-width: 240px;
  font-family: "Open Sans", "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif;
  color: #1E1C18;
  background-color: #F6F5F6; }

header, footer, nav, aside, article, section {
  display: block; }

img, object, video, media, embed {
  max-width: 100%;
  height: auto; }

iframe {
  max-width: 100%; }

a {
  color: #5E98B0; }
  a:hover {
    color: #376071; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased; }

h1 a, h2 a, h3 a, h4 a, h5 a {
  text-decoration: none; }

h1 {
  color: #5E98B0; }

h2, h3, h4, h5, h6 {
  color: #5E98B0; }

.chromeframe {
  padding: 20px 0;
  text-align: center;
  background-color: #E9CD4A;
  color: #1E1C18;
  font-size: 1.2em;
  position: relative;
  z-index: 1000; }
  .chromeframe p {
    max-width: 700px;
    margin: 0 auto; }

.main-header {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 6px solid #4E707D;
  border-bottom: 3px solid #A95C40; }
  @media screen and (min-width: 770px) {
    .main-header {
      padding-top: 15px;
      padding-bottom: 15px; } }

.top-nav a, .primary-nav a {
  text-decoration: none;
  display: block;
  padding: 4px; }

.top-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.4rem; }

.primary-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.6;
  padding-left: 100px; }
  .primary-nav .logo {
    max-width: 80px;
    position: absolute;
    top: 10px;
    left: 7vw; }
  @media screen and (min-width: 770px) {
    .primary-nav {
      justify-content: center;
      flex-wrap: no-wrap;
      margin-left: auto;
      margin-right: auto;
      max-width: 1000px;
      padding-left: 0; }
      .primary-nav .logo {
        max-width: 120px;
        order: unset;
        position: static; }
      .primary-nav a {
        width: 14%; } }
  @media screen and (min-width: 770px) {
    .primary-nav {
      justify-content: space-between; }
      .primary-nav a {
        width: auto; } }

.main-content-wrapper {
  padding-top: calc(30px + 2vw);
  padding-bottom: calc(30px + 2vw); }

.main-footer {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #A95C40;
  color: #F6F5F6; }
  .main-footer .copyright {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 0; }
  .main-footer a {
    color: white; }
  .main-footer .contact-info {
    font-size: 1.4rem;
    margin-bottom: 40px; }
    .main-footer .contact-info p {
      line-height: 1.25;
      margin-bottom: 1.25rem; }
    .main-footer .contact-info strong {
      text-transform: uppercase; }
  @media screen and (min-width: 770px) {
    .main-footer .contact-info {
      position: relative;
      padding-left: 180px; }
      .main-footer .contact-info:before {
        content: '';
        display: block;
        width: 140px;
        height: 140px;
        background: transparent url(../images/QQA_logoWhite.png) no-repeat 0 0;
        background-size: contain;
        position: absolute;
        left: 0;
        top: 0; } }

.panel {
  padding-top: calc(30px + 2vw);
  padding-bottom: calc(30px + 2vw); }
  .panel .panel-header {
    text-align: center;
    margin: 0;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 750px; }
    .panel .panel-header .title {
      font-size: calc(3rem + 0.25vw);
      margin-bottom: 40px;
      position: relative; }
      .panel .panel-header .title:after {
        content: '';
        display: block;
        height: 2px;
        width: 50px;
        background-color: #E9CD4A;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px; }
    .panel .panel-header .intro {
      margin-bottom: 4rem;
      text-align: left; }
      .panel .panel-header .intro strong {
        color: #5E98B0;
        text-transform: uppercase; }

.sub-nav {
  margin-left: auto;
  margin-right: 0;
  max-width: 300px;
  background-color: #5E98B0;
  color: #F6F5F6;
  margin-bottom: 40px; }
  .sub-nav a {
    color: #F6F5F6; }
  .sub-nav .sub-nav-title, .sub-nav .nav-title {
    color: #F6F5F6;
    padding: 20px 20px;
    font-size: calc(3rem + 0.25vw);
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: right;
    position: relative; }
    .sub-nav .sub-nav-title:after, .sub-nav .nav-title:after {
      display: block;
      content: '';
      width: 80%;
      height: 1px;
      background-color: white;
      position: absolute;
      right: 0;
      bottom: 0; }
  .sub-nav ul {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: calc(1.6rem + 0.25vw);
    font-weight: normal;
    list-style-type: none; }
    .sub-nav ul li {
      margin-bottom: 0;
      text-align: right; }
    .sub-nav ul li.selected a, .sub-nav ul a.current_item, .sub-nav ul a:hover {
      background-color: rgba(30, 28, 24, 0.2); }
    .sub-nav ul a {
      display: block;
      line-height: 1.25;
      padding: 15px 20px;
      text-decoration: none; }

.donors-list {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  margin-bottom: 40px; }
  .donors-list .banner-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
  .donors-list .banner {
    width: 48%;
    padding: 20px 10px; }
  @media screen and (min-width: 770px) {
    .donors-list .banner {
      width: 25%; } }

.slider {
  position: relative; }

.slick-prev,
.slick-next {
  -webkit-font-smoothing: antialiased;
  position: absolute;
  display: block;
  height: 40px;
  width: 40px;
  padding: 0;
  line-height: 0px;
  font-size: 40px;
  font-weight: normal;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  color: rgba(246, 245, 246, 0.3);
  top: 40%;
  z-index: 1000;
  transform: translate(0, -50%);
  border: none;
  outline: none; }
  .slick-prev i, .slick-prev svg,
  .slick-next i,
  .slick-next svg {
    margin: 0; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    color: rgba(255, 255, 255, 0.9);
    background-color: transparent; }

.slick-prev {
  left: 0; }

.slick-next {
  right: 0; }

@media screen and (min-width: 770px) {
  .slick-prev,
  .slick-next {
    top: 50%; }
  .slick-prev {
    left: 5px; }
  .slick-next {
    right: 5px; } }

label {
  color: black;
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-size: 1.4rem;
  text-transform: uppercaes;
  font-weight: bold; }

input[type=text], input[type=email], input[type=number],
input[type=password], textarea {
  border: 1px solid #777;
  background-color: white;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 5px 5px;
  color: #1E1C18;
  border-radius: 4px; }
  input[type=text]:focus, input[type=email]:focus, input[type=number]:focus,
  input[type=password]:focus, textarea:focus {
    background-color: #ffff99;
    outline: none; }

select {
  border: 1px solid #777;
  font-size: 16px;
  font-size: 1.6rem;
  width: 100%; }
  select:focus {
    outline: none; }

button, input[type=submit], .button {
  background-color: #5E98B0;
  display: inline-block;
  border: 0;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: bold;
  padding: 10px 24px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  color: #F6F5F6;
  font-family: "Open Sans", "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: background-color ease-in 0.2s; }
  button i, input[type=submit] i, .button i {
    margin-left: 10px; }
  button:hover, input[type=submit]:hover, .button:hover {
    background-color: #487d93;
    color: #F6F5F6; }
  button:focus, input[type=submit]:focus, .button:focus {
    outline: none; }

.button.alt-button {
  background-color: #E9CD4A;
  color: #F6F5F6; }
  .button.alt-button:hover {
    background-color: #e3c01d; }

form .form-group .help-block {
  display: block;
  margin-top: 5px; }

form .form-group:has-error {
  border: 2px solid #DF2133;
  padding: 7px 5px; }
  form .form-group:has-error .help-block {
    color: #DF2133; }

.pagination {
  margin: 40px 0 40px 0;
  text-align: center; }
  .pagination:after {
    content: "";
    display: table;
    clear: both; }
  .pagination a, .pagination .next, .pagination .prev {
    margin-right: 10px;
    background-color: #E9CD4A;
    display: inline-block;
    border: 0;
    font-size: 1.4rem;
    line-height: 1;
    font-weight: bold;
    padding: 10px 24px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    color: #F6F5F6;
    font-family: "Open Sans", "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    transition: background-color ease-in 0.2s;
    max-width: 130px; }
    .pagination a i, .pagination .next i, .pagination .prev i {
      margin-left: 10px; }
    .pagination a:hover, .pagination .next:hover, .pagination .prev:hover {
      background-color: #e3c01d;
      color: #F6F5F6; }
  .pagination .next {
    float: right; }
    .pagination .next i {
      margin-left: 10px; }
  .pagination .prev {
    float: left; }
    .pagination .prev i {
      margin-right: 10px; }
  .pagination .disabled {
    opacity: 0; }
  .pagination .current {
    font-weight: normal;
    font-size: 14px;
    font-size: 1.4rem;
    text-transform: uppercase;
    display: none; }

/*** Content div for Post & Page text content */
.content-header, .page-header {
  margin-bottom: 60px;
  position: relative; }
  .content-header .title, .page-header .title {
    font-size: calc(3.6rem + 0.5vw);
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 30px; }
  .content-header .parent-title, .page-header .parent-title {
    font-size: calc(1.6rem + 0.25vw);
    line-height: 1;
    margin-bottom: 0.5rem;
    text-transform: uppercase; }
  .content-header .title:after, .page-header .title:after {
    content: '';
    display: block;
    width: 176px;
    height: 1px;
    background-color: #E9CD4A;
    position: absolute;
    bottom: 0;
    left: 0; }
  .content-header .intro-copy, .page-header .intro-copy {
    margin-left: auto;
    margin-right: auto;
    max-width: 650px;
    text-align: center;
    margin-bottom: 40px; }
  .content-header .date, .page-header .date {
    font-size: 1.8rem; }

.textblock {
  margin-bottom: 30px; }

.content {
  font-size: 16px;
  font-size: 1.6rem; }
  .content:after {
    content: "";
    display: table;
    clear: both; }
  .content a {
    font-weight: 700;
    text-decoration: none; }
  .content li {
    line-height: 1.25;
    margin-bottom: 0.5em; }
    .content li:last-child {
      margin-bottom: 0; }
  .content ul li {
    list-style-type: none;
    position: relative; }
    .content ul li:before {
      position: absolute;
      top: 0;
      left: -1.5em;
      content: '●';
      font-size: 75%;
      padding-top: 3px;
      color: #777; }
  .content h2 {
    font-size: calc(calc(3.6rem + 0.5vw) * 0.7);
    color: #A95C40; }
  .content h3 {
    font-size: calc(calc(3.6rem + 0.5vw) * 0.6);
    color: #4E707D; }
  .content h4 {
    font-size: calc(calc(3.6rem + 0.5vw) * 0.5);
    color: #4E707D;
    font-weight: bold; }
  .content strong {
    color: #4E707D; }
  .content blockquote {
    font-style: normal;
    font-size: 1.8rem;
    line-height: 1.5; }
  .content dl {
    margin-bottom: 20px; }
    .content dl dt {
      font-size: 1.6rem;
      margin-bottom: 5px; }
    .content dl dd {
      margin: 0;
      padding: 0;
      line-height: 1.5;
      margin-bottom: 25px; }
  .content figure, .content img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 1.5em; }
    .content figure.alignleft, .content figure.left, .content img.alignleft, .content img.left {
      float: left;
      max-width: 50%;
      margin-right: 1.5em; }
    .content figure.alignright, .content figure.right, .content img.alignright, .content img.right {
      float: right;
      max-width: 50%;
      margin-left: 1.5em; }
    .content figure.center, .content img.center {
      margin-left: auto;
      margin-right: auto;
      max-width: 75%; }
  .content .button {
    max-width: 300px; }
  .content .image-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; }
    .content .image-grid img {
      margin-right: 10px; }
  @media screen and (max-width: 770px) {
    .content figure.alignleft, .content figure.alignright, .content figure.left, .content figure.right, .content figure.center, .content img.alignleft, .content img.alignright, .content img.left, .content img.right, .content img.center {
      float: none;
      width: auto;
      max-width: 100%;
      padding-right: 0;
      margin-left: 0; }
    .content .image-grid {
      flex-direction: column; } }

.post header time span {
  display: none; }

.home .section-header {
  background: #dfdfdf url(../images/backgrounds/join-us-bg.png) no-repeat 50% 50%;
  background-size: cover;
  border-bottom: 2px solid #A95C40; }
  @media screen and (min-width: 870px) {
    .home .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .home .section-header .hp-slider-container {
        width: 55vw; }
      .home .section-header .join-us-callout {
        width: 45vw; } }
  @media screen and (min-width: 1200px) {
    .home .section-header .hp-slider-container {
      width: 65vw; }
    .home .section-header .join-us-callout {
      width: 35vw; } }

.hp-slider-container .slide {
  color: white;
  position: relative; }
  .hp-slider-container .slide .title {
    margin-bottom: 1rem;
    font-size: calc(2rem + 0.5vw);
    color: rgba(255, 255, 255, 0.9);
    text-transform: uppercase;
    font-weight: bold; }
  .hp-slider-container .slide .slide-content {
    background-color: #4E707D;
    padding: 10px 15px; }
    .hp-slider-container .slide .slide-content p {
      font-size: 1.2rem; }
    .hp-slider-container .slide .slide-content p:last-of-type {
      margin-bottom: 0; }
  @media screen and (min-width: 770px) {
    .hp-slider-container .slide .slide-content {
      background-color: transparent;
      position: absolute;
      left: 0;
      right: 0;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      bottom: 15px; }
      .hp-slider-container .slide .slide-content .title {
        font-size: calc(3rem + 0.5vw); }
      .hp-slider-container .slide .slide-content p {
        font-size: 1.4rem; } }

.home .join-us-callout {
  padding: 10px 4vw;
  font-size: 1.5rem; }
  .home .join-us-callout .title {
    color: #4E707D;
    font-size: calc(3.6rem + 0.5vw);
    line-height: 1.25;
    margin-bottom: 2rem; }
  @media screen and (min-width: 1200px) {
    .home .join-us-callout .title {
      font-size: calc(3.6rem + 0.5vw); } }

.home .panel.who-we-serve {
  background-color: #4E707D;
  color: white; }
  .home .panel.who-we-serve .title, .home .panel.who-we-serve a {
    color: white; }
  .home .panel.who-we-serve a {
    text-decoration: none; }

.home .panel.who-we-serve .banner-list .banner {
  padding: 20px 10px; }
  .home .panel.who-we-serve .banner-list .banner .title {
    text-transform: uppercase;
    font-size: 2.4rem;
    margin-bottom: 1rem; }
  .home .panel.who-we-serve .banner-list .banner p {
    font-size: 1.4rem; }
  .home .panel.who-we-serve .banner-list .banner .button {
    font-size: 1.4rem; }

@media screen and (min-width: 770px) {
  .home .panel.who-we-serve .banner-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: calc(-1 * (7vw / 2.5));
    margin-right: calc(-1 * (7vw / 2.5)); }
    .home .panel.who-we-serve .banner-list .banner {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; } }

@media screen and (min-width: 1200px) {
  .home .panel.who-we-serve .banner-list {
    margin-left: calc(-1 * (7vw / 1.5));
    margin-right: calc(-1 * (7vw / 1.5)); }
    .home .panel.who-we-serve .banner-list .banner {
      width: 20%; } }

.home .main-content-wrapper {
  padding: 0; }

.home .panel.what-we-do .banner {
  -webkit-font-smoothing: antialiased;
  background-color: #444;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  text-align: center;
  overflow: hidden; }
  .home .panel.what-we-do .banner a {
    display: block;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding-top: 25vw;
    padding-bottom: 25vw; }
    .home .panel.what-we-do .banner a:hover .title {
      transform: scale(1.15);
      color: rgba(255, 255, 255, 0.8); }
  .home .panel.what-we-do .banner .title {
    text-transform: uppercase;
    color: #F6F5F6;
    font-weight: bold;
    font-size: 2.4rem;
    margin-bottom: 0;
    transition: transform ease 0.2s, color ease-in 0.3s; }
  .home .panel.what-we-do .banner.preserve {
    background-image: url(../images/what-we-do/preserve.png); }
  .home .panel.what-we-do .banner.advocate {
    background-image: url(../images/what-we-do/advocate.png); }
  .home .panel.what-we-do .banner.educate {
    background-image: url(../images/what-we-do/educate.png); }
  .home .panel.what-we-do .banner.events {
    background-image: url(../images/what-we-do/events.png); }

@media screen and (min-width: 450px) {
  .home .panel.what-we-do {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0; }
    .home .panel.what-we-do .banner-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch; }
    .home .panel.what-we-do .banner {
      width: 50%; }
      .home .panel.what-we-do .banner a {
        padding-top: 20vw;
        padding-bottom: 20vw; } }

@media screen and (min-width: 870px) {
  .home .panel.what-we-do .banner {
    width: 25%; }
    .home .panel.what-we-do .banner a {
      padding-top: 10vw;
      padding-bottom: 10vw; } }

.info-panel {
  background-color: #ececec; }
  .info-panel .col-container > div {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #dfdfdf; }
  .info-panel .panel-col-title {
    color: #A95C40;
    font-size: 3.6rem;
    margin-bottom: 4rem; }
  .info-panel .curran-hall-callout img {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2), 0 3px 9px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; }
  .info-panel .blog-feed > footer {
    padding-top: 30px;
    display: flex;
    justify-content: flex-end; }
  @media screen and (min-width: 770px) {
    .info-panel .col-container {
      display: flex;
      justify-content: space-between;
      align-items: stretch; }
    .info-panel .col-container > div {
      width: 48%;
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0; } }

.home .donors .panel-footer {
  text-align: center; }
