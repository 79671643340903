/*** Content div for Post & Page text content */
$page_header_font_size: calc(3.6rem + 0.5vw);
.content-header, .page-header {
    margin-bottom: 60px;
    position: relative;
    .title {
        font-size: $page_header_font_size;
        position: relative;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    .parent-title {
        font-size: calc(1.6rem + 0.25vw);
        line-height: 1;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }
    .title:after {
        content: '';
        display: block;
        width: 176px;
        height: 1px;
        background-color: $YELLOW;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .intro-copy {
        @include block-center();
        max-width: 650px;
        text-align: center;
        margin-bottom: 40px;
    }
    .date {
        font-size: 1.8rem;
    }
}



.textblock {
    margin-bottom: 30px;
    .title {
    }
}

.content {
    @include clearfix();
    @include font-size(1.6);

    a {
        font-weight: 700;
        text-decoration: none;
    }

    li {
        line-height: 1.25;
        margin-bottom: 0.5em;
        &:last-child {
            margin-bottom: 0;
        }
    }
    ul li {
        list-style-type: none;
        position: relative;
        &:before {
            position: absolute;
            top: 0;
            left: -1.5em;
            content: '●';
            font-size: 75%;
            padding-top: 3px;
            color: $MEDIUM_GREY;
        }
    }

    h2, h3, h4, h5 {

    }
    h2 {
        font-size: calc(#{$page_header_font_size} * 0.7);
        color: $ORANGE;

    }
    h3 {
        font-size: calc(#{$page_header_font_size} * 0.6);
        color: $DARK_BLUE;

    }
    h4 {
        font-size: calc(#{$page_header_font_size} * 0.5);
        color: $DARK_BLUE;
        font-weight: bold;
    }
    strong {
        color: $DARK_BLUE;
    }

    blockquote {
        font-style: normal;
        font-size: 1.8rem;
        line-height: 1.5;
    }
    dl {
        margin-bottom: 20px;
        dt {
            font-size: 1.6rem;
            margin-bottom: 5px;
        }
        dd {
            margin: 0;
            padding: 0;
            line-height: 1.5;
            margin-bottom: 25px;
        }
    }

    figure, img {
        max-width: 100%;
        height: auto;
        display: block;
        margin-bottom: 1.5em;


        &.alignleft, &.left {
            float: left;
            max-width: 50%;
            margin-right: 1.5em;
        }
        &.alignright, &.right {
            float: right;
            max-width: 50%;
            margin-left: 1.5em;
        }
        &.center {
            margin-left: auto;
            margin-right: auto;
            max-width: 75%;
        }
    }

    .button {
        max-width: 300px;
    }
    .image-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        img {
            margin-right: 10px;
        }
    }

    @include respond-to("max-width: " + $SMALL_SCREEN_WIDTH) {
        figure, img {
            &.alignleft, &.alignright, &.left, &.right, &.center {
                float: none;
                width: auto;
                max-width: 100%;
                padding-right: 0;
                margin-left: 0;
            }
        }
        .image-grid {
            flex-direction: column;
        }
    }
}
