@import "partials/base";

.main-header {
    @extend %wrapper;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 6px solid $DARK_BLUE;
    border-bottom: 3px solid $ORANGE;
    @include respond-to("min-width: " + $SMALL_SCREEN_WIDTH) {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}
.top-nav, .primary-nav {
    a {
        text-decoration: none;
        display: block;
        padding: 4px;
    }
}
.top-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.4rem;
}
.primary-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;

    font-size: 1.6;
    padding-left: 100px;
    .logo {
        max-width: 80px;
        position: absolute;
        top: 10px;
        left: $WRAPPER_PADDING;

    }
    @include respond-to("min-width: " + $SMALL_SCREEN_WIDTH) {
        justify-content: center;
        flex-wrap: no-wrap;
        @include block-center();
        max-width: 1000px;
        padding-left: 0;
        .logo {
            max-width: 120px;
            order: unset;
            position: static;
        }
        a {
            width: 14%;
            //margin-left: 5px;
            //margin-right: 5px;
        }
    }
    @include respond-to("min-width: " + $SMALL_SCREEN_WIDTH) {
        justify-content: space-between;
        a {
            width: auto;
        }
    }
}

$MAIN_CONTENT_V_PADDING: calc(30px + 2vw);
.main-content-wrapper {
    @extend %wrapper;
    padding-top: $MAIN_CONTENT_V_PADDING;
    padding-bottom: $MAIN_CONTENT_V_PADDING;
}


.main-footer {
    @extend %wrapper;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: $ORANGE;
    color: $WHITE;
    .copyright {
        font-size: 1.2rem;
        text-align: center;
        margin-bottom: 0;
    }
    a {
        color: white;
    }
    .contact-info {
        font-size: 1.4rem;
        margin-bottom: 40px;
        p {
            line-height: 1.25;
            margin-bottom: 1.25rem;
        }
        strong {
            text-transform: uppercase;
        }
    }
    @include respond-to("min-width: " + $SMALL_SCREEN_WIDTH) {
        .contact-info {
            position: relative;
            padding-left: 180px;
            &:before {
                content: '';
                display: block;
                width: 140px;
                height: 140px;
                background: transparent url(../images/QQA_logoWhite.png) no-repeat 0 0;
                background-size: contain;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

}


.panel {
    @extend %wrapper;
    padding-top: calc(30px + 2vw);
    padding-bottom: calc(30px + 2vw);

    .panel-header {
        text-align: center;
        margin: 0;
        padding: 0;
        @include block-center;
        max-width: 750px;
        .title {
            font-size: calc(3rem + 0.25vw);
            margin-bottom: 40px;
            position: relative;
            &:after {
                content: '';
                display: block;
                height: 2px;
                width: 50px;
                background-color: $YELLOW;
                position: absolute;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                margin-top: 20px;
            }
        }
        .intro {
            margin-bottom: 4rem;
            text-align: left;
            strong {
                color: $BLUE;
                text-transform: uppercase;
            }
        }
    }
}
.sub-nav {
    margin-left: auto;
    margin-right: 0;
    max-width: 300px;
    background-color: $BLUE;
    color: $WHITE;
    a {
        color: $WHITE;
    }

    margin-bottom: 40px;

    .sub-nav-title, .nav-title {
        color: $WHITE;
        padding: 20px 20px;
        font-size: calc(3rem + 0.25vw);
        font-weight: bold;
        margin-bottom: 10px;
        line-height: 1.2;
        text-transform: uppercase;
        text-align: right;
        position: relative;
        &:after {
            display: block;
            content: '';
            width: 80%;
            height: 1px;
            background-color: white;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
    ul {
        margin: 0;
        padding: 0;
        @include heading-font();
        font-size: calc(1.6rem + 0.25vw);
        font-weight: normal;
        list-style-type: none;
        li {
            margin-bottom: 0;
            text-align: right;
        }
        li.selected a, a.current_item, a:hover {
            background-color: rgba($BLACK, 0.2);
        }
        a {
            display: block;
            line-height: 1.25;
            padding: 15px 20px;
            text-decoration: none;
        }
    }
}

.donors-list {
    @include block-center;
    max-width: 1200px;
    margin-bottom: 40px;
    .banner-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    .banner {
        width: 48%;
        padding: 20px 10px;
    }
    @include respond-to("min-width: " + $SMALL_SCREEN_WIDTH) {
        .banner {
            width: 25%;
        }
    }
}
@import "partials/slick_theme";
@import "partials/form";
@import "partials/pagination";
@import "partials/page_content";
@import "partials/blog";
@import "partials/home";
