$YELLOW: #E9CD4A;
$BLACK: #1E1C18;
$WHITE: #F6F5F6;
$BLUE: #5E98B0;
$DARK_BLUE: #4E707D;
$ORANGE: #A95C40;

// Greys
$LIGHT_GREY: #dfdfdf;
$MEDIUM_GREY: #777;
$DARK_GREY: #444;

// Theme Colors
$LINK_COLOR: $BLUE;
$TXT_COLOR: $BLACK;
$PRIMARY_BUTTON_BACKGROUND_COLOR: $BLUE;
$PRIMARY_BUTTON_TXT_COLOR: $WHITE;

$ALT_BUTTON_BACKGROUND_COLOR: $YELLOW;
$ALT_BUTTON_TXT_COLOR: $WHITE;

$PRIMARY_HEADING_COLOR: $BLUE;
$SECONDARY_HEADING_COLOR: $BLUE;

$ERROR_COLOR: #DF2133;
